
import { play, stop } from './playActions';

import type { AudioElement } from './index';

export type SetCurrentTrackIdAction = {
  type: 'SET_CURRENT_TRACK_ID';
  id: string;
  context: AudioElement;
};

export const SET_CURRENT_TRACK_ID = 'SET_CURRENT_TRACK_ID';

export function setTrack(id: string, context: any): SetCurrentTrackIdAction {
  return {
    type: SET_CURRENT_TRACK_ID,
    id,
    context,
  };
}

export const TIMEUPDATE = 'TIMEUPDATE';

class AudioContext {
  static events: Array<string>;

  static timeupdate = time => ({
    type: TIMEUPDATE,
    time,
  });

  context: any;
  dispatch: Function;
  playing?: Promise<void>;

  constructor(
    url: string,
    dispatch: Function
  ) {
    const context = document.createElement('audio');

    this.handleTimeupdate = this.handleTimeupdate.bind(this);
    context.addEventListener('timeupdate', this.handleTimeupdate, false);

    this.handleEnded = this.handleEnded.bind(this);

    context.addEventListener('ended', this.handleEnded, false);

    if (url) {
      url = url.replace('https://', '').replace('http://', '');
      // set https:// as default
      context.setAttribute('src', `https://${url}`);
    }

    this.context = context;
    this.dispatch = dispatch;
    this.playing = undefined;
  }

  destroy(): void {
    this.dispatch(stop());
    this.context.removeEventListener('timeupdate', this.handleTimeupdate);
    this.context.removeEventListener('ended', this.handleEnded);
    this.context = null;
  }

  handleTimeupdate(): void {
    this.dispatch(AudioContext.timeupdate(this.context.currentTime));
  }

  handleEnded(): void {
    this.dispatch(nextTrack()); // eslint-disable-line no-use-before-define
    this.dispatch(play());
  }

  async play(): Promise<void> {
    if (this.playing) {
      await this.playing;
    }

    this.playing = this.context.play();
  }

  pause(): void {
    this.context.pause();
  }

  stop(): void {
    this.context.pause();
    this.context.currentTime = 0;
  }

  setTime(time: number): void {
    this.context.currentTime = time;
  }
}

export function setCurrentTrackId(id: string): Function {
  let context: AudioContext;

  return (dispatch, getState) => {
    const { player, tracks } = getState();
    const playing = player.playing;
    if (player.currentTrackId === id) {
      return false;
    }

    if (player.context instanceof AudioContext) {
      player.context.destroy();
    }

    const url = tracks.getIn(['entities', String(id), 'audio', 'url']);
    context = new AudioContext(url, dispatch);

    dispatch(setTrack(id, context));

    if (playing) {
      dispatch(play());
    }
  };
}

export function previousTrack(): Function {
  return (dispatch, getState) => {
    const { player } = getState();
    const currentId = player.get('currentTrackId');
    const ids = player.get('sponsors').count()
      ? player.get('sponsors').toArray()
      : player.get('favorites')
          .concat(player.get('tracks'))
          .toArray();

    let index = ids.indexOf(currentId) - 1;

    if (index < 0) {
      index = ids.length - 1;
    }

    const id = ids[index];

    dispatch(setCurrentTrackId(id));
  };
}

export function nextTrack(): Function {
  return (dispatch, getState) => {
    const { player } = getState();
    const currentId = player.get('currentTrackId');
    const ids = player.get('sponsors').count()
      ? player.get('sponsors').toArray()
      : player.get('favorites')
          .concat(player.get('tracks'))
          .toArray();

    let index = ids.indexOf(currentId) + 1;

    if (index >= ids.length) {
      index = 0;
    }

    const id = ids[index];

    dispatch(setCurrentTrackId(id));
  };
}

export type SetFavorite = {
  type: 'SET_FAVORITE' | 'UNSET_FAVORITE';
  trackId: string;
};

export const SET_FAVORITE = 'SET_FAVORITE';
export function setFavorite(trackId: string): SetFavorite {
  return {
    type: SET_FAVORITE,
    trackId,
  };
}

export const UNSET_FAVORITE = 'UNSET_FAVORITE';

export function toggleFavorite(trackId: string): Function {
  return (dispatch, getState) => {
    const { player, tracks } = getState();
    const favorites = player.get('favorites');
    const favorite = favorites.contains(trackId);

    if (!favorite) {
      // @ts-ignore
      if (typeof window.ga !== 'undefined') {
        const { currentTrackId } = player;
        const track = tracks.getIn([
          'entities',
          currentTrackId,
          'title',
        ]);

        // @ts-ignore
        window.ga('send', 'event', 'Story Engagement', 'Add Story / Queue', track);
      }

      dispatch(setFavorite(trackId));
      dispatch(setCurrentTrackId(trackId));
    } else {
      dispatch({ type: UNSET_FAVORITE, trackId });
    }
  };
}
